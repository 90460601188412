<template>
	<van-overlay :show="myShow"
	             bind:click="onClickHide">
		<div class="popupRemind">
			<div class="popup_remind_txt">
				您的爱跟推月度版即将在当地时间 <span class="color-primary">{{ userInfo.subscribeExpireDate }}</span> 日到期，到期后将无法继续使用此功能，请及时续费。
			</div>
			<div class="bottom_btn_ensure flex h-center color-primary" @click="myShow = false">
				确定
			</div>
		</div>
	</van-overlay>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

/**
 * 到期提醒弹窗
 */
export default {
	name: 'TheDueReminder',
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { slots, emit }) {
		const myShow = computed({
			set: (val) => {
				emit('update:show', val)
			},
			get: () => {
				return props.show
			}
		})
		const store = useStore()
		return {
			myShow,
			userInfo: store.getters.userInfo
		}
	}

}
</script>

